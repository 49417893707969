import React, { Fragment } from 'react';
import { Container, Popover, Button, TextField, Box, Typography, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { AlertingComponent, ManageDateField, DateUtils } from 'react-frontend-utils';
import { ThemeColors } from '../Theme';

import { SignatureForm } from './SignatureForm';

export class CompleteTaxForm extends AlertingComponent {

    styles = {
        button: {
            marginLeft: 10,
            marginRight: 10
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    };

 
    _okCallback;
    _cancelCallback;
    
    _thisYear = (new Date()).getFullYear();  //this year
    _dateFormat = DateUtils.DateFormatType.US;

    _effectiveDateFieldRef = React.createRef();

    constructor(props) {
        super(props);
        this._okCallback = props.okCallback;
        this._cancelCallback = props.cancelCallback;
        this.state.taxID = null;
        this.state.entity = null;
        this.state.taxClassification = null;
        this.state.addressLine1 = null;
        this.state.addressLine2 = null;
        this.state.city = null;
        this.state.state = null;
        this.state.zip = null;
        this.state.effectiveDate = null;

        this.state.idType = "SSN";
        this.state.signature = null;
    }

    _clear = () => {
        this.setState({taxID: null, entity: null, taxClassification: null, addressLine1: null, addressLine2: null, city: null, state: null, zip: null, effectiveDate: null, idType: "SSN", signature: null});
    }
   
    _renderField = (label, field, required = false) => {
        return <TextField label={label + (required ? " *" : "")} variant='outlined' fullWidth={true} value={this.state[field]}
                        InputLabelProps={{ shrink: true }} size="small" 
                        onChange={(event) => {this.setState({[field]: event.target.value})}}
                />;
    }
    
    
    _okButtonPressed = () => {

        // No current ID, so effective date is the beginning of the year
        if (!this.props.currentTaxpayerID) {
            this.state.effectiveDate = DateUtils.jsonDateString(DateUtils.startOfYear(), false);
        }

        //If any of the required fields are empty, show an alert
        if (!this.state.entity || !this.state.taxClassification || !this.state.addressLine1 || !this.state.city || !this.state.state || !this.state.zip || !this.state.taxID || !this.state.effectiveDate) {
            this.showConfirmAlert("Missing Information", "Please fill in all required fields before continuing.", 'red');
            return;
        }

        //Limit entity and address fields to 40 characters
        if (this.state.entity.length > 40 || this.state.addressLine1.length > 40 || (this.state.addressLine2 && this.state.addressLine2.length > 40) || this.state.city.length > 40) {
            this.showConfirmAlert("Field Length Exceeded", "The entity name, address, and city fields are limited to 40 characters.", 'red');
            return;
        }

        if (this.state.taxID === this.props.currentTaxpayerID) {
            this.showConfirmAlert("TIN number unchanged", "Use this form only when changing your current tax identification number.", 'red');
            return;
        }


        if (this.props.currentEffectiveDate >= DateUtils.parseJsonDate(this.state.effectiveDate, false)) {
            this.showConfirmAlert("Invalid Effective Date", "The effective date must be after the previous effective date of " + DateUtils.dateFormat(this.props.currentEffectiveDate, this._dateFormat), 'red');
            return;
        }


        //If SSN is selected but the format is not correct, show an alert
        // Regex for SSN format XXX-XX-XXXX
        const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
        // Regex for EIN format XX-XXXXXXX
        const einRegex = /^\d{2}-\d{7}$/;

        //If SSN is selected but the format is not correct, show an alert
        if (this.state.idType === "SSN" && !ssnRegex.test(this.state.taxID)) {
            this.showConfirmAlert("Invalid SSN", "Please enter a valid SSN in the format XXX-XX-XXXX.", 'red');
            return;
        }
        //If EIN is selected but the format is not correct, show an alert
        if (this.state.idType === "EIN" && !einRegex.test(this.state.taxID)) {
            this.showConfirmAlert("Invalid EIN", "Please enter a valid EIN in the format XX-XXXXXXX.", 'red');
            return;
        }

        const add2 = this.state.addressLine2 ? this.state.addressLine2.trim() : "";

        // Entity name, address, and city is restricted to characters A-Z a-z 0-9 space - &
        const addressRegex = /^[a-zA-Z0-9\s\-&]*$/;

        if (!addressRegex.test(this.state.entity) || !addressRegex.test(this.state.addressLine1) || (add2 && !addressRegex.test(add2)) || !addressRegex.test(this.state.city)) {
            this.showConfirmAlert("Invalid Characters in Entity Name or Address", "Only letters, numbers, spaces, hyphens, and ampersands are allowed.", 'red');
            return;
        }

        // Zip code must be 5 digits or 5 digits followed by a hyphen and 4 digits
        const zipRegex = /^\d{5}(-\d{4})?$/;
        if (!zipRegex.test(this.state.zip)) {
            this.showConfirmAlert("Invalid Zip Code", "Please enter a valid numeric zip code in the format XXXXX or XXXXX-XXXX.", 'red');
            return;
        }


        const taxData = {
            taxpayerID: this.state.taxID,
            tinType: this.state.idType,
            // semicolon seperated field: payee;line1;line2;city;state;zip;country
            taxMailingAddress: this.state.entity.trim() + ";" + this.state.addressLine1.trim() + ";" + add2 + ";" + this.state.city.trim() + ";" + this.state.state + ";" + this.state.zip.trim() + ";US", 
            taxClassification: this.state.taxClassification,
            exemptFrom1099: false,
            effectiveDate: this.state.effectiveDate,
            signature: this.state.signature
        }

        this._okCallback(taxData);
        this._clear()  //clear text for next time
    }


    
    _cancelButtonPressed = () => {
        this._cancelCallback();
        this._clear()  //clear text for next time
    }
    
    
    _effectiveDateFieldChanged = (json, val) => {
       
        const inputDate = val ? DateUtils.parseJsonDate(val, false) : null;

        //input date must be this year
        if (inputDate && inputDate.getFullYear() !== this._thisYear) {
            this.showConfirmAlert("Invalid Effective Date", "The effective date must be in the current year.", 'red');
            return;
        }


        this._effectiveDateFieldRef.current.accept();  
        this.setState({effectiveDate: val});
    }
    
    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }
 
    _hasSignature = () => {
        return this.state.signature && this.state.signature.length > 0;
    }

    
    render() {

        const stateList = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", 
                           "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", 
                           "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY", "AS", "GU", "MP", "PR", "VI"];
        
        const classificationList = ["Individual/sole proprietor", "C Corporation", "S Corporation", "Partnership", "Trust/Estate", "LLC (Taxed as C Corporation)", 
                                    "LLC (Taxed as S Corporation)", "LLC (Taxed as Partnership)", "Non-profit Organization"];

        let maxWidth = window.innerWidth * 0.8;
        if (maxWidth > 1200)
            maxWidth = 1200;
                
        return (
            <Popover open={this.props.isOpen} 
                anchorReference="anchorPosition"
                anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}  >
                
                {this.getConfirmAlertComponent()}   

                <Box style={{margin: 20, width: maxWidth}}>
                    <Typography variant="h5" style={{fontWeight: 'bold', marginBottom: 20, textTransform: 'uppercase'}}>Request for Taxpayer Identification and Certification (W-9 equivalent)</Typography>  

                    <Typography variant="body2" style={{color: ThemeColors.appBarBackground, marginBottom: 30, marginTop: 20}}>Access Granted Systems (the form W-9 requester), who is required to file an
                            1099-K return with the IRS, is requesting you complete this form because they must obtain your correct taxpayer identification number (TIN), which
                            may be your social security number (SSN), individual taxpayer identification number (ITIN), adoption taxpayer identification number
                            (ATIN), or employer identification number (EIN), to report on an information return the amount paid to you.</Typography>  

                    {this.props.currentTaxpayerID ?
                        <Typography variant="body1" style={{color: ThemeColors.appBarBackground, fontWeight: 'bold', marginBottom: 30, marginTop: 20}}>Your current TIN is already on file. Complete this form to change your TIN.</Typography>
                        : null
                    }

                    <Typography variant="body2" style={{fontStyle: 'italic', color: ThemeColors.darkGray, marginBottom: 8, marginTop: 20}}>Enter the name of the entity or individual. For a sole proprietor or disregarded entity, enter the owner’s name.</Typography>
                    {this._renderField("Name of Entity", "entity", true)}

                    <Typography variant="body2" style={{fontStyle: 'italic', color: ThemeColors.darkGray, marginBottom: 8, marginTop: 20}}>Enter the tax classification of the entity. A disregarded entity should instead select the appropriate
                            tax classification of its owner. If none of the selections apply: <span style={{color: 'red'}}>STOP</span>. Press Cancel and contact your Access Granted account representative instead.</Typography>
                    <Autocomplete
                        size='small'
                        value={this.state.taxClassification}
                        onChange={(event, newValue) => { this.setState({taxClassification: newValue}); }}
                        inputValue={this.state.taxClassificationValue}
                        onInputChange={(event, newValue) => { this.setState({taxClassificationValue: newValue}); }}
                        options={classificationList}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Federal Tax Classification *" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />

                    <Typography variant="body2" style={{fontStyle: 'italic', color: ThemeColors.darkGray, marginBottom: 8, marginTop: 20}}>Enter the US mailing address where the 1099 form will be mailed.</Typography>
                    <div style={{display: 'flex', justifyContent: 'left', gap: 5, alignItems: 'center', marginBottom: 10}}>
                        {this._renderField("Address Line 1", "addressLine1", true)}
                        {this._renderField("Address Line 2", "addressLine2")}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'left', gap: 5, alignItems: 'center'}}>
                        {this._renderField("City", "city", true)}
                        <Autocomplete
                            size='small'
                            value={this.state.state}
                            onChange={(event, newValue) => { this.setState({state: newValue}); }}
                            inputValue={this.state.stateValue}
                            onInputChange={(event, newValue) => { this.setState({stateValue: newValue}); }}
                            options={stateList}
                            blurOnSelect
                            renderInput={(params) => <TextField {...params} label="State *" variant="outlined" InputLabelProps={{ shrink: true }} />}
                        />
                        {this._renderField("Postal Code", "zip", true)}
                    </div>
                    
                    <Typography variant="body2" style={{fontStyle: 'italic', color: ThemeColors.darkGray, marginBottom: 5, marginTop: 40}}>Enter your Taxpayer Identification Number (TIN). If you are a resident alien and
                            you do not have, and are not eligible to get, an SSN, your TIN is your IRS ITIN. Enter it as the Social security number.
                            If you are a sole proprietor and you have an EIN, you may enter either your SSN or EIN. If you are a single-member LLC that is disregarded as an entity separate from its owner, enter the owner’s SSN (or EIN, if the owner has
                            one). If the LLC is classified as a corporation or partnership, enter the entity’s EIN.</Typography>
                    <div style={{display: 'flex', justifyContent: 'left', gap: 20, alignItems: 'center'}}>
                        <FormControl component="fieldset">
                            <RadioGroup row name="idType" value={this.state.idType} onChange={(e) => this.setState({ idType: e.target.value })}>
                                <FormControlLabel value="SSN" control={<Radio color='primary' />} label="Social Security Number (SSN)" />
                                <FormControlLabel value="EIN" control={<Radio color='primary' />} label="Employer Identification Number (EIN)" />
                            </RadioGroup>
                        </FormControl>
                        {this._renderField(this.state.idType === "SSN" ? "SSN (XXX-XX-XXXX)" : "EIN (XX-XXXXXXX)", "taxID", true)}
                    </div>

                    {this.props.currentTaxpayerID ?
                        <Fragment>
                            <Typography variant="body2" style={{fontStyle: 'italic', color: ThemeColors.darkGray, marginBottom: 8, marginTop: 20}}>Enter the effective date this year of the TIN change</Typography>
                            <ManageDateField hasNever={false} json="effectiveDate" maxYear={this._thisYear} minYear={this._thisYear}
                                            onFieldChange={this._effectiveDateFieldChanged} 
                                            onParseError={this._dateParseError}
                                            label="Effective Date" 
                                            ref={this._effectiveDateFieldRef}
                                            style={{maxWidth: 300}}
                                            changedBackgroundColor='white'
                                            dateFormat={this._dateFormat}
                                            calendarColor={'gray'}
                                            isReadOnly={false} editable={true}/>
                        </Fragment>
                        : null
                    }
            
                    <Typography variant="body1" style={{fontWeight: 'bold', marginBottom: 5, marginTop: 40}}>Under penalties of perjury, I certify that: 
                        <span>
                            <ul>
                                <li>The number entered above is my correct taxpayer identification number</li>
                                <li>I am not subject to backup withholding because (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue
                                    Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am
                                    no longer subject to backup withholding</li>
                                <li>I am a U.S. citizen or resident alien</li>
                                <li>I have the full legal authority to complete this form on behalf of {this.props.communityName}.</li>
                            </ul>
                        </span>
                    </Typography>
                        
                    <SignatureForm signature={this.state.signature} signatureDrawn={(sig) => this.setState({signature: sig})} />
                    
                    <Container style={this.styles.container}>    
                        <Button onClick={this._okButtonPressed} disabled={!this._hasSignature()} color="primary" variant="outlined" style={this.styles.button}>OK</Button>
                        <Button onClick={this._cancelButtonPressed} variant="outlined" style={this.styles.button}>Cancel</Button>
                    </Container>
                </Box>
            </Popover>
        );
        
        
    }


}